import React from 'react'
import { connect } from 'react-redux'
import dayjs from 'dayjs'

const currentYear = dayjs().format('YYYY')
const getSaveaddColor = (tabSelect) => (tabSelect === 0 ? 'saveadd-primary-color' : 'saveadd-secondary-color')

const Footer = React.forwardRef((props, ref) => (
  <footer className={`py-3 ${getSaveaddColor(props.tabSelectedMainMenu)}`} ref={ref}>
    <p className="text-center w-100 mb-0">
      &copy;{currentYear} Saveadd - {`versão ${props.version}`}
    </p>
  </footer>
))

const mapStateToPros = (state) => ({
  tabSelectedMainMenu: state.main.tabSelectedMainMenu,
})

const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(mapStateToPros, mapDispatchToProps)(Footer)
